(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("window"), require("null"), require("solid-auth-client"));
	else if(typeof define === 'function' && define.amd)
		define(["window", "null", ], factory);
	else if(typeof exports === 'object')
		exports["$rdf"] = factory(require("window"), require("null"), require("solid-auth-client"));
	else
		root["$rdf"] = factory(root["window"], root["null"], root["solid"]["auth"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__29__, __WEBPACK_EXTERNAL_MODULE__46__, __WEBPACK_EXTERNAL_MODULE__47__) {
return 