/**
 * A Dummy log
 * @module log
 */
const log = {
  debug (x) {},
  warn (x) {},
  info (x) {},
  error (x) {},
  success (x) {},
  msg (x) {},
}
export default log
